$main-color: #034e86;
$white: #ffffff;
$text-black: #333333;
$medic: #6c57c6;
$office: #07b0a5;
$info: #0595c4;
$alumni: #2ab140;

$about: #79a203;
$depart: #c99005;
$selection: #c93b04;
$info-admission: #b50581;
$hospital: #972fc3;
$library: #3c08d6;
