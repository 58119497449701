//z-indexの基本設定
$layer: (
  modal: 100,
  header-most-top: 51,
  header-top: 51,
  header: 50,
  tooltip: 20,
  tooltip-bottom: 10,
  default-plus: 2,
  default: 1,
);

@mixin z-index($key) {
  z-index: map-get($layer, $key);
}
