@use "variable" as var;

.footer {
  padding: 20px 40px;
  background-color: #d3d3d3;

  @media #{var.$md} {
    padding-top: 12px;
    padding-bottom: 16px;
  }
}

.copyright {
  font-size: 14px;
  line-height: 1.35;
  letter-spacing: 0.1em;
  text-align: center;

  @media #{var.$md} {
    font-size: 10px;
    line-height: 1.4;
  }
}
