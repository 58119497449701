*,
*::before,
*::after {
  box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
  padding: 0;
}

// コンテンツの高さが画面サイズより低いとき
body {
  min-height: 100vh;
  min-height: 100dvh; /* ios用 */
  display: flex;
  flex-direction: column;
}

main {
  display: block;
  flex: 1;
}

// メディア要素のデフォルトを改善
img,
picture,
video,
canvas,
svg {
  max-width: 100%;
  vertical-align: middle;
}

// テーブルのセル間のスペースを削除します
table {
  border-collapse: collapse;
}

a {
  //BootStrap競合対策
  &:hover {
    color: inherit;
  }
}
