@use "variable" as var;

.header {
  position: relative;
  @include var.z-index(header);
}

.header-container {
  width: 100%;
  background-color: var.$main-color;
}

.header-wrapper {
  display: grid;
  grid-template-columns: 445px 1fr 80px;
  grid-template-rows: 55px 1fr;
  row-gap: 16px;
  max-width: 1920px;
  margin: 0 auto;
  height: 145px;
  padding: 0 100px;

  @media #{var.$xxl} {
    padding: 0 20px;
    row-gap: 15px;
    height: 182px;
  }

  @media #{var.$lg} {
    grid-template-columns: 40% 1fr 80px;
  }

  @media #{var.$md} {
    width: 100%;
    grid-template-columns: auto 78px;
    grid-template-rows: 27px 62px 1fr;
    row-gap: 10px;
    padding: 18px 10px 0;
    height: 234px;
  }
}

.header-title {
  grid-column: 1;
  grid-row: 1/3;
  margin: auto 0;

  @media #{var.$md} {
    grid-column: 1;
    grid-row: 1/3;
    width: 260px;
    margin-bottom: 7px;
  }
}

.header-title__link {
  transition: opacity 0.3s;

  @media (hover: hover) {
    &:hover {
      opacity: 0.65;
    }
  }
}

//メインメニュー
.header-list {
  grid-column: 2;
  grid-row: 2;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 5px 4px;
  margin-right: 19px;
  list-style: none;
  color: var.$white;

  @media #{var.$xxl} {
    height: 80px;
    width: 340px;
    flex-wrap: wrap;
    align-items: center;
    margin-right: 4px;
    margin-left: auto;
  }

  @media #{var.$md} {
    grid-column: 1 / 3;
    grid-row: 3;
    margin: 5px auto 0;
    justify-content: center;
    align-items: center;
  }
}

.header-list__link {
  text-decoration: none;
  color: inherit;
  font-size: 13px;
  line-height: 1.31;
  letter-spacing: 0;
  vertical-align: middle;
  width: 157px;
  height: 37px;
  padding: 10px;
  display: flex;
  align-items: center;
  position: relative;
  transition: opacity 0.3s;

  .header-list__item--medic & {
    background: var.$medic;
  }

  .header-list__item--office & {
    background: var.$office;
  }

  .header-list__item--info & {
    background: var.$info;
  }

  .header-list__item--alumni & {
    background: var.$alumni;
  }

  @media (hover: hover) {
    &:hover {
      opacity: 0.6;
    }
  }

  &::after {
    content: "";
    background-image: url("../img/header/header-arrow.svg");
    position: absolute;
    top: 50%;
    right: 7px;
    width: 8px;
    height: 8px;
    transform: translate(0, -50%);
  }
}

//検索・英語PC
.header-sub--pc {
  display: block;
  grid-column: 2;
  grid-row: 1;
  margin-top: auto;

  @media #{var.$xxl} {
    grid-column: 2/4;
  }

  @media #{var.$md} {
    display: none;
  }
}

.header-sub-pc__button {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-right: 19px;

  @media #{var.$xxl} {
    margin-right: 0px;
  }
}

.header-sub-pc__en-link {
  margin-right: 12px;
  transition: opacity 0.3s;

  @media (hover: hover) {
    &:hover {
      opacity: 0.6;
    }
  }
}

.header-search-pc__input-box {
  background: var.$white;
  width: 184px;
  height: 27px;
  position: relative;
}

.header-search-pc__input {
  border: none;
  background: none;
  outline: none;
  width: 80%;
  height: 27px;
  font-size: 16px;
  padding: 0 0 0 4px;
}

.header-search-pc__button {
  position: absolute;
  top: 2px;
  right: 2px;
  border-style: none;
  background-color: transparent;
  padding: 0;
}

.header-search-pc__button-image {
  width: 23px;
  height: 23px;
  display: block;
}

//検索・英語SP
.header-sub-sp {
  display: none;

  @media #{var.$md} {
    display: block;
    grid-column: 2;
    grid-row: 1;
  }
}

.header-sub-sp__button {
  @media #{var.$md} {
    display: flex;
  }
}

.header-sub-sp__en-link {
  @media #{var.$md} {
    margin-right: 5px;
  }
}

.open-button {
  width: 27px;
  height: 27px;
  padding: 0;
  border: none;
  background-image: url("../img/header/search-button.png");
  background-size: contain;
  background-repeat: no-repeat;

  [data-is-header-search-toggle="true"] & {
    background-image: url("../img/header/search-button-close.png");
  }
}

.header-search-sp {
  @media #{var.$md} {
    width: 100%;
    height: calc(100vh - 45px);
    height: calc(100dvh - 45px);
    padding: 32px 42px;
    background-color: rgba(#000000, 0.61);
    position: absolute;
    top: 45px;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
    @include var.z-index(header-most-top);

    [data-is-header-search-toggle="true"] & {
      opacity: 1;
      visibility: visible;
    }
  }
}

.header-search-sp__input-box {
  @media #{var.$md} {
    background: var.$white;
    width: 100%;
    height: 43px;
    position: relative;
  }
}

.header-search-sp__input {
  @media #{var.$md} {
    border: none;
    background: none;
    outline: none;
    width: 80%;
    height: 100%;
    font-size: 16px;
    padding: 0 0 0 4px;
  }
}

.header-search-sp__button {
  @media #{var.$md} {
    position: absolute;
    top: 4px;
    right: 4px;
    border-style: none;
    background-color: transparent;
    padding: 0;
  }
}

.header-search-sp__button-image {
  @media #{var.$md} {
    width: 36px;
    height: 36px;
    display: block;
  }
}

//ハンバーガーメニュー
.header-hamburger {
  margin: auto 0;
  grid-column: 3;
  grid-row: 1/3;
  position: relative;
  background: var.$white;
  cursor: pointer;
  width: 80px;
  height: 80px;
  border: 1px solid var.$main-color;
  margin-top: 28px;

  @media #{var.$xxl} {
    grid-column: 3;
    grid-row: 2/3;
    margin-top: 0;
  }

  @media #{var.$md} {
    width: 62px;
    height: 62px;
    grid-column: 2;
    grid-row: 2;
    margin-left: auto;
  }

  [data-is-headernav="true"] & {
    background: var.$main-color;
    border: 1px solid var.$white;

    @media #{var.$md} {
      background: var.$white;
      border: 1px solid var.$main-color;
    }
  }
}

.header-hamburger__stick {
  display: inline-block;
  transition: all 0.4s;
  position: absolute;
  top: 35%;
  left: 50%;
  width: 45%;
  height: 3px;
  background: var.$main-color;
  border-radius: 3px;

  [data-is-headernav="true"] & {
    background: var.$white;
    border: 1px solid var.$white;

    @media #{var.$md} {
      background: var.$main-color;
      border: 1px solid var.$main-color;
    }
  }

  &:nth-of-type(1) {
    transform: translate(-50%, -13px);
    transition: top 0.3s, transform 0.3s;

    @media #{var.$md} {
      transform: translate(-50%, -8px);
    }

    [data-is-headernav="true"] & {
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }

  &:nth-of-type(2) {
    transform: translate(-50%, 0);
    transition: opacity 0.3s;

    [data-is-headernav="true"] & {
      opacity: 0;
    }
  }

  &:nth-of-type(3) {
    transform: translate(-50%, 13px);
    transition: bottom 0.3s, transform 0.3s;

    @media #{var.$md} {
      transform: translate(-50%, 8px);
    }

    [data-is-headernav="true"] & {
      bottom: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

.header-hamburger__text {
  background: url("../img/header/hamburger-menu.svg");
  position: absolute;
  top: 75%;
  left: 50%;
  width: 48px;
  height: 12px;
  transform: translate(-50%, -50%);
  background-size: contain;
  background-repeat: no-repeat;

  @media #{var.$md} {
    width: 36px;
    height: 10px;
  }

  [data-is-headernav="true"] & {
    background-image: url("../img/header/hamburger-close.svg");

    @media #{var.$md} {
      width: 37px;
      height: 9px;
      background-image: url("../img/header/hamburger-close-sp.svg");
    }
  }
}

//ヘッダーナビ
.header-nav {
  position: absolute;
  top: 145px;
  left: 0;
  width: 100%;
  height: calc(100vh - 145px);
  height: calc(100dvh - 145px);
  padding: 50px 0 80px;
  background-image: url("../img/header/header-back.png");
  background-size: max(100%, 1500px) auto;
  background-repeat: no-repeat;
  background-position: bottom right;
  overflow: auto;
  overscroll-behavior: none;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;

  @media #{var.$xxl} {
    top: 182px;
    height: calc(100vh - 182px);
    height: calc(100dvh - 182px);
  }

  @media #{var.$md} {
    top: calc(234px - 107px);
    height: calc(100vh - 234px + 107px);
    height: calc(100dvh - 234px + 107px);
    padding-top: 20px;
    padding-bottom: 40px;
  }

  [data-is-headernav="true"] & {
    opacity: 1;
    visibility: visible;
  }
}

.header-nav__container {
  max-width: 960px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start;
  gap: 30px;

  @media #{var.$xl} and (hover: hover) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media #{var.$lg} {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 20px;
  }

  @media #{var.$md} {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

.header-nav__wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  align-items: flex-start;
  list-style: none;

  @media #{var.$xl} and (hover: hover) {
    grid-template-columns: 1fr;
  }

  @media #{var.$lg} {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

.header-nav__title {
  font-size: 19px;
  line-height: 1.58;
  letter-spacing: 0.08em;
  font-weight: 700;
  text-decoration: none;
  vertical-align: middle;
  color: var.$text-black;
  position: relative;
  border-bottom: 2px solid #efefef;
  padding-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: opacity 0.3s;

  @media #{var.$lg} {
    font-size: 24px;
    padding-bottom: 19px;
  }

  & > .header-nav__toggle-button {
    display: none;
  }

  @media #{var.$md} {
    & > .header-nav__toggle-button {
      display: block;
      transform: translateY(-19px);
    }
  }

  &::after {
    content: "";
    position: absolute;
    display: block;
    width: 23%;
    bottom: -2px;
    border-bottom-width: 2px;
    border-bottom-style: solid;
  }

  .header-nav__box--about & {
    &::after {
      border-bottom-color: var.$about;
    }
  }

  .header-nav__box--depart & {
    &::after {
      border-bottom-color: var.$depart;
    }
  }

  .header-nav__box--selection & {
    &::after {
      border-bottom-color: var.$selection;
    }
  }

  .header-nav__box--info & {
    &::after {
      border-bottom-color: var.$info-admission;
    }
  }

  @media (hover: hover) {
    &:hover {
      opacity: 0.6;
    }
  }
}

.header-nav__list {
  list-style: none;

  @media #{var.$md} {
    display: none;
  }
}

.header-nav__item {
  position: relative;

  @media (hover: hover) {
    &:hover {
      & > .header-nav__sub-list {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.header-nav__main-link {
  font-size: 14px;
  line-height: 1.36;
  letter-spacing: 0.08em;
  vertical-align: text-bottom;
  color: var.$text-black;
  text-decoration: none;
  border-bottom: 1px solid #efefef;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 0 10px 16px;
  position: relative;

  @media #{var.$lg} {
    font-size: 20px;
    padding: 16px 0 16px 20px;
  }

  &::before {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    top: 50%;
    left: 0px;
    transform: translate(0, -75%);
  }

  .header-nav__box--about & {
    &::before {
      background-color: var.$about;
    }
  }

  .header-nav__box--depart & {
    &::before {
      background-color: var.$depart;
    }
  }

  .header-nav__box--selection & {
    &::before {
      background-color: var.$selection;
    }
  }

  .header-nav__box--info & {
    &::before {
      background-color: var.$info-admission;
    }
  }

  &[target="_blank"] {
    &::after {
      content: "";
      display: inline-block;
      background-image: url("../img/header/external.svg");
      background-size: contain;
      background-repeat: no-repeat;
      width: 10px;
      height: 10px;
      margin-left: 6px;
      flex-shrink: 0;
    }
  }

  @media (hover: hover) {
    &:hover {
      background-color: var.$main-color;
      color: var.$white;

      &::before {
        display: none;
      }

      & > .header-nav__toggle-button {
        background-image: url("../img/header/plus-icon-white.svg");

        &[data-toggle-button="open"] {
          background-image: url("../img/header/minus-icon-white.svg");
        }
      }
    }

    &[target="_blank"] {
      &:hover {
        &::after {
          background-image: url("../img/header/external-white.svg");
        }
      }
    }

    &--dir {
      &::after {
        content: "";
        position: absolute;
        background: url("../img/header/arrow-right.svg");
        top: 50%;
        right: 0;
        width: 8px;
        height: 8px;
        transform: translate(0, -75%);
        background-size: contain;
        background-repeat: no-repeat;
        transition: transform 0.3s;
      }

      &:hover {
        &::after {
          background-image: url("../img/header/header-arrow.svg");
          transform: translate(-8px, -75%);
        }
      }
    }
  }

  @media #{var.$lg} {
    &--dir {
      &::after {
        display: none;
      }
    }
  }
}

.header-nav__sub-list {
  position: absolute;
  top: 0;
  left: 100%;
  min-width: 200px;
  border-left: 1px solid #efefef;
  background-color: var.$main-color;
  margin: 0;
  padding: 0 5px;
  list-style: none;
  @include var.z-index(header-top);
  visibility: hidden;
  opacity: 0;
  color: var.$white;

  @media #{var.$lg}, (hover: none) {
    position: static;
    opacity: 1;
    visibility: visible;
    border: none;
    width: calc(100% - 20px);
    padding: 0 6px;
    margin-left: 20px;
    display: none;
  }

  .header-nav__sub-list {
    left: calc(100% + 5px);

    @media #{var.$lg}, (hover: none) {
      width: 100%;
      margin-left: 0;
      padding-left: 30px;
      padding-right: 0;
      border-top: 1px solid #efefef;
    }
  }

  .header-nav__toggle-button {
    background-image: url("../img/header/plus-icon-white.svg");

    &[data-toggle-button="open"] {
      background-image: url("../img/header/minus-icon-white.svg");
    }
  }
}

.header-nav__sub-item {
  width: 100%;
  position: relative;

  &:not(:first-of-type) {
    border-top: 1px solid #efefef;
  }

  @media (hover: hover) {
    &:hover {
      & > .header-nav__sub-list {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.header-nav__sub-link {
  width: 100%;
  font-size: 14px;
  line-height: 1.36;
  letter-spacing: 0.08em;
  text-decoration: none;
  color: inherit;
  padding: 10px 5px;
  display: block;
  position: relative;

  @media #{var.$lg} {
    font-size: 18px;
    padding: 18px 6px;
  }

  &[target="_blank"] {
    &::after {
      content: "";
      display: inline-block;
      background-image: url("../img/header/external-white.svg");
      background-size: contain;
      background-repeat: no-repeat;
      width: 10px;
      height: 10px;
      margin-left: 6px;
      flex-shrink: 0;
    }
  }

  @media (hover: hover) {
    &:hover {
      opacity: 0.6;
    }

    &--dir {
      &::after {
        content: "";
        position: absolute;
        background-image: url("../img/header/header-arrow.svg");
        top: 50%;
        right: 0;
        width: 8px;
        height: 8px;
        transform: translate(0, -75%);
        background-size: contain;
        background-repeat: no-repeat;
        transition: transform 0.3s;
      }

      &:hover {
        &::after {
          transform: translate(-8px, -75%);
        }
      }
    }
  }

  @media #{var.$lg} {
    &--dir {
      &::after {
        display: none;
      }
    }
  }
}

.header-nav__toggle-button {
  display: none;

  @media #{var.$lg}, (hover: none) {
    display: block;
    width: 16px;
    height: 16px;
    background-image: url("../img/header/plus-icon-main-color.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    background-color: transparent;
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    @include var.z-index(header-top);
    cursor: pointer;
  }

  &[data-toggle-button="open"] {
    background-image: url("../img/header/minus-icon-main-color.svg");
  }
}

.header-nav__other-link {
  display: block;
  text-decoration: none;
  font-size: 16px;
  line-height: 1.33;
  color: var.$white;
  padding: 8px 16px;
  margin-top: 12px;
  position: relative;
  transition: opacity 0.3s;

  &--hospital {
    background-color: var.$hospital;
  }

  &--library {
    background-color: var.$library;
  }

  &[target="_blank"] {
    &::after {
      content: "";
      display: inline-block;
      background-image: url("../img/header/external-white.svg");
      background-size: contain;
      background-repeat: no-repeat;
      width: 14px;
      height: 14px;
      position: absolute;
      bottom: 5px;
      right: 5px;

      @media #{var.$lg}, (hover: none) {
        width: 21px;
        height: 21px;
        bottom: 10px;
        right: 10px;
      }
    }
  }

  @media (hover: hover) {
    &:hover {
      opacity: 0.6;
      color: var.$white;
    }
  }

  @media #{var.$lg}, (hover: none) {
    font-size: 24px;
    padding: 14px 24px;
    margin-top: 24px;
  }
}
